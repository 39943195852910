import React , { useEffect } from 'react';
import './App.css';
import NavBar from './components/Navbar';
import Banner from './components/Banner';
import Projects from './components/Projects';
import About from './components/About';
import Contact from './components/Contact';
import Footer from './components/Foot';
import 'bootstrap/dist/css/bootstrap.min.css';


function App() {

  useEffect(()=>{
    document.title = "Jay's Space"
  })

  return (
    <div className="App">
      <NavBar />
      <Banner />
      <About />
      <Projects />
      <Footer />
    </div>

  );
}

export default App;
