//Banner
import header_Img from "./assets/header-img.svg"
//About
import Left_Image from "./assets/man.jpeg";
//Project
import image_getchance from "./assets/webicon2.jpg"
import image_airplane from './assets/airplane.jpg';
import image_mobileapp from "./assets/mobileicon2.jpg"
import image_cardealer from "./assets/cardealer.jpg"
import image_stock from "./assets/stock3.jpg"
import image_todolist from "./assets/todoprocess.jpg"
import image_aibot from './assets/aibot.jpg';
import image_getchance_robot from './assets/aibot2.jpg';
//Contact
import contact_Img from './assets/contact-img.svg';
import linkedin_Icon from './assets/linkedin.svg';
import github_Icon from './assets/github.svg';
import timemachine_Icon from './assets/time-machine4.png'


//Banner
export const banner_info = {
    welcome_title : 'Welcome to My Page',
    description:"I have a lot of experience in system design and interface design, and I am proficient in full-stack program application development. If you are interested in using technology to help your business, please don't hesitate to contact me!",
    headerImg: header_Img
}


//About
export const about_lists = {
    LeftImage : Left_Image,
    skillsList: [
      'React', 'Vue', 'Node.js',
      'Python' , 'Flask', 'ASP.NET',
      'AWS', 'Azure', 'Kotlin',
      'Java', 'SQL / NOSQL', 'C, C++',
      'C#',  'Github', 'Postman',
      'Figma', 'WordPress'],

    educationList: [
      'IT Programming Diploma | NSCC', 
      'Bachelor of Commerce | SMU'],

    certificationList: [
      'Front End Developer certification | META',
      'AWS Cloud Practitioner Essentials | AWS', 
      'Open-source AI Models | Scrimba', 
      'UX design certification | Google', 
      'Entrepreneurship certification | SMU'],

    description:'An entrepreneurial FULL STACK programmer with over 6 years of experience in business operations, financial planning, and technology development. Demonstrated leadership in guiding start-ups, including founding a technology company during my university studies, managing a team, and successfully launching an Online Learning Application in the Apple Store and various Android marketplaces. '
};

//Project
export const projects_info = {

    projectsData:[
    {
      id: 1,
      title: 'Commercial Website',
      description: 'A real bunissue website which was built by using WordPress.',
      imageUrl: image_getchance,
      linkUrl:'https://getchance.ca/en/'
    },
    {
      id: 2,
      title: 'Global FilghtMap',
      description: 'This is a project combines React with API data to track real time Aircraft.',
      imageUrl: image_airplane,
      linkUrl:'https://liuinfinite.com/flymap'
    },
    {
      id: 3,
      title: 'AI Consuting Robot',
      description: 'This project integrates AWS, Vue.js, and AI training to build a consulting AI robot.',
      imageUrl: image_getchance_robot,
      linkUrl:'https://liuinfinite.com/getchance_robot'
    },
    {
      id: 4,
      title: 'Stock Search Web App',
      description: 'Stock search engine build by using React.',
      imageUrl: image_stock,
      linkUrl:'https://liuinfinite.com/stockmarket'
    },
    {
      id: 5,
      title: 'Music Learning Mobile App',
      description: 'A real Moblie app published in the past. I was the project manager to lead the team.',
      imageUrl: image_mobileapp,
      linkUrl:'https://www.figma.com/proto/ky6x5l93YGJgTSaPsDlmvG/Jay%E2%80%98s-Music-APP-Prototype?node-id=8-2&scaling=scale-down&page-id=0%3A1'
    },
    {
      id: 6,
      title: 'To-do List',
      description: 'Simple project created by using basic HTML, CSS, and JavaScript.',
      imageUrl: image_todolist,
      linkUrl:'https://liuinfinite.com/todolist/index.html'
    }
  ]
}

//Contact
export const contact_info = {
    emailAddress : 'w0470637@nscc.ca',
    contactImg : contact_Img,
    linkedinIcon : linkedin_Icon,
    githubIcon : github_Icon ,
    linkedin_url : "https://www.linkedin.com/in/liuinfinite",
    github_url : "https://github.com/Jaynscc",
    timemachineIcon : timemachine_Icon,
    timemachine_url : "https://liuinfinite.com/oldversionsite/",
    Tooltip_context : "Buckle up! Our Time Machine takes you on a blast to the past of this site!"


}


//Footer
export const footer_info = {
    context:'Powered By Jay   —   Copyright2024©',
    
}
