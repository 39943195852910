
import { Container, Nav, Row, Col} from 'react-bootstrap';
import React, { useState } from 'react';
import { about_lists }from '../data';


function FillExample() {
    const [selectedTab, setSelectedTab] = useState('/skill');

    // 处理点击标签的事件
    const handleTabClick = (eventKey) => {
        setSelectedTab(eventKey);
    };

    const renderContent = () => {
        if (selectedTab === '/skill') {
        return <div>
                    <ul style={{ display: 'flex', flexWrap: 'wrap', maxWidth: '100%' }}>
                        {about_lists.skillsList.map((skill,index)=>(<li key={index} style={{ flex:'0 0 33%' }}>{skill}</li>))}
                    </ul>
                </div>;
        }
        else if (selectedTab === '/education') {
        return <div>
                    <ul>
                        {about_lists.educationList.map((edu,index)=>(<li key={index}>{edu}</li>))}
                    </ul>
                </div>;
        }
        else if (selectedTab === '/certification') {
        return <div>
                    <ul>
                        {about_lists.certificationList.map((cer,index)=>(<li key={index}>{cer}</li>))}
                    </ul>
                </div>;
        }
        else {
        return <p>No content available for this tab.</p>;
        }
    };


    return (
        <Container id='about' style={{marginBottom:'200px'}}>
            <Row>
                <Col md={5}>
                    <img src={about_lists.LeftImage} className="w-100" id='aboutImg' alt='myPhoto'></img>
                </Col>

                <Col md={7}>
                    <Container id='aboutMeContext' >
                        <div style={{ textAlign: 'left' }}>
                            <h1 style={{marginBottom:'20px', marginTop:'30px'}}>ABOUT ME</h1>
                            <div id='aboutMainContext'>
                            {about_lists.description}
                            </div>
                        </div>

                        <div id='tabPart'>
                            <Nav fill variant="tabs" defaultActiveKey={selectedTab} onSelect={handleTabClick}>
                                <Nav.Item>
                                    <Nav.Link eventKey="/skill">Skills</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="/education">Education</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="/certification">Certifications</Nav.Link>
                                </Nav.Item>
                            </Nav>

                            <div id='tabContext'>
                                {renderContent()}
                            </div>
                        </div>
                    </Container>
                </Col>
            </Row>
        </Container>
    );
}

export default FillExample;