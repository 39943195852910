import React from "react";
import { Container } from 'react-bootstrap';
import { footer_info } from "../data";

function Footer(){
    return(
         <Container id="footer" className="breathing-text">
                <p>
                   {footer_info.context}
                </p>
         </Container>
    )
}

export default Footer