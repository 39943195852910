// Projects.js
import React from 'react';
import { Container, Card, Row, Col} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { projects_info } from '../data';


const Projects = () => {

  return (
    <Container id='project'>
      <h1 style={{marginBottom:'50px'}}>PROJECTS</h1>
      <Row>
          {projects_info.projectsData.map((project) => (
            <Col key={project.id} md={4} sm={12}>
              {/* Wrap the Card with Link */}
              <Link to={project.linkUrl} target="_blank" style={{ textDecoration: 'none', color: 'inherit' }}>
                <Card className="custom-card" id="projectCard" style={{ margin: '10px' }}>
                  <Card.Img id="prjectImg" variant="top" src={project.imageUrl} alt={project.title} />
                  <Card.Body>
                    <Card.Title>{project.title}</Card.Title>
                    <Card.Text id='projectText' style={{minHeight:'80px',textAlign:'center',marginTop:'20px'}}>{project.description}</Card.Text>
                  </Card.Body>
                </Card>
              </Link>
            </Col>
          ))}
        </Row>
    </Container>

  );
};

export default Projects;
