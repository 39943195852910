// Navbar.js
import React , { useState, useEffect } from 'react';
import { Navbar, Nav, Container,Image} from 'react-bootstrap';
import { Link as RouterLink } from 'react-router-dom';
import logoIcon from "../assets/ion_infinite-sharp.svg"

const NavBar = () => {
  const [isNavFixed, setIsNavFixed] = useState(false);

  const handleScroll = () => {
    const scrollTop = window.scrollY;
    const isNavFixed = scrollTop > 0;

    setIsNavFixed(isNavFixed);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

    const parts=[
        {   id: 1,
            title:'HOME',
            link:'#banner'
        },
        {   id: 2,
            title:'ABOUT',
            link:'#about'
        },
        {   id: 3,
            title:'PROJECTS',
            link:'#project'
        },
        {   id: 4,
            title:'CONTACT',
            link:'#contact'
        }
    ]

  return (
    <>
    <div style={{ height: '72px', display: isNavFixed ? 'block' : 'none' }}></div>
    <Navbar expand="lg"  className={`bg-dark ${isNavFixed ? 'fixed-top' : ''}`} style={{ transition: 'background-color 0.5s' }}>
    <Container>
      <Navbar.Brand as={RouterLink} to="/">
      <Image src={logoIcon} alt="Logo" fluid />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" style={{backgroundColor:'#8b76f1'}} />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="justify-content-end flex-grow-1 pe-3">
            {parts.map((part)=>(
              <Nav.Link href={part.link} key={part.id}>
              {part.title}
              </Nav.Link>
            ))}
        </Nav>
      </Navbar.Collapse>
    </Container>
  </Navbar>
  </>


  );
};

export default NavBar;



