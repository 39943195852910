import React,{ useState, useEffect, useCallback, useMemo}from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { banner_info } from '../data';


const Banner = () => {

  // useMemo to ensure roles array is only initialized once
  const roles = useMemo(() => ['Full-Stack', 'Front-End', 'UX-Designer'], []);
  const [text, setText] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);
  const [roleIndex, setRoleIndex] = useState(0);

  const updateText = useCallback(() => {
    const currentRole = roles[roleIndex];
    const currentText = text.trim();
    const delay = 2000;

    if (!isDeleting) {
      if (currentText.length < currentRole.length) {
        setText((prevText) => prevText + currentRole[currentText.length]);
      } else {
        setIsDeleting(true);
        setTimeout(() => {
          setIsDeleting(false);
          setText('');
          setRoleIndex((prevIndex) => (prevIndex + 1) % roles.length);
        }, delay);
      }
    } else {
      if (currentText.length > 0) {
        setText((prevText) => prevText.slice(0, -1));
      } else {
        setIsDeleting(false);
      }
    }
  }, [text, isDeleting, roleIndex, roles]);  // Include roles here to ensure it captures the latest state

  useEffect(() => {
    const speed = 200; // Speed of typing and deleting
    const timerId = setInterval(updateText, speed);
    return () => clearInterval(timerId); // Clean up the interval on component unmount
  }, [updateText]);  // Now updateText is a stable function that only changes if its dependencies change



  return (
    <Container className="d-flex flex-wrap" id='banner'>
      <Row className="w-100">
        <Col md={8} className="order-md-1 order-2">
          {/* Left side with text content */}
          <div className='text-start'>
            <br/>
            <br/>
            <h5>{banner_info.welcome_title}</h5>
            <br/>
            <h1>This is Jay_Liu</h1>
            <h1 id='rolesBlock'>I'm &#123; <b id='rolesText'>{text}</b> &#125; Developer</h1>
            <br/>
            <br/>
            <br/>
            <p style={{ paddingRight: '40px' }}>{banner_info.description}</p>
          </div>
        </Col>
        <Col md={4} className="order-md-2 order-1">
          {/* Right side with image */}
          <div>
            <img src={banner_info.headerImg} alt="bannerPhoto" className="w-100" />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Banner;
